import { css } from 'styled-components'

export const breakpoints = {
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200
}

export default Object.keys(breakpoints).reduce((acc, label) => {
	acc[label] = (first, ...interpolations) => css`
		@media (max-width: ${breakpoints[label]}px) {
			${css(first, ...interpolations)}
		}
	`
	return acc
}, {})
