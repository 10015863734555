import styled from "styled-components"
import Button from "../Button"
import Input from "../Input"
import media from "../../theme/breakpoints"

export const NewsletterContainer = styled.div`
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 3px;
  background: ${props => props.theme.colors.secondary};
  max-width: 82rem;
  margin: 10rem auto 3rem;
  text-align: center;
  position: relative;
  padding: 3rem;
  box-sizing: border-box;
    
  ${media.md`
    margin-top: 6rem;
  `}

  ${media.sm`
    padding: 3rem 2rem;
  `}
`

export const Title = styled.h3`
  font-family: ${props => props.theme.fontFamily.mainHeader};
`

export const ColoredBar = styled.div`
  background: ${props => props.theme.colors.main};
  width: 100%;
  height: 6px;
  border-radius: 2px 2px 0 0;
  position: absolute;
  top: 0;
  left: 0;
`

export const SubmitButton = styled(Button)`
  min-width: 11rem;
  margin-left: 1.2rem;

  ${media.sm`
    margin-left: 0;
  `}
`

export const Description = styled.p`
  font-size: 1.8rem;
  line-height: 25px;
  letter-spacing: 0.5px;
  
  ${media.sm`
    font-size: 1.4rem;
    line-height: 1.2;
  `}
`

export const StyledInput = styled(Input)`
  max-width: 25rem;
  width: 100%;

  ${media.sm`
    width: auto;
    max-width: unset;
    margin-bottom: 2rem;
  `}
`

export const Form = styled.form`
  display: flex;
  justify-content: center;

  ${media.sm`
    flex-direction: column;
  `}
`