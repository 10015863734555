import React, { useState, useEffect } from "react"

const AppInitialState = {
  width: 1200
}

export const AppContext = React.createContext(AppInitialState)

export const AppContextProvider = ({ children }) => {
  let initialWidth = 1200
  if (typeof window !== `undefined`) {
    initialWidth = window.innerWidth
  }

  const [width, updateWidth] = useState(initialWidth)
  const values = {
    width
  }

  useEffect(() => {
    const updateWidthState = () => updateWidth(window.innerWidth)
    window.addEventListener("resize", updateWidthState)
    return () => {
      window.removeEventListener("resize", updateWidthState)
    }
  }, [])

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>
}
