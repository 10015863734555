import { graphql, useStaticQuery } from "gatsby"

export const useAllCategories = () => {
  const { allMdx } = useStaticQuery(
    graphql`
      query ALL_CATEGORIES_QUERY {
        allMdx(
          filter: { frontmatter: { collection: { eq: "category" } } }
          limit: 1000
        ) {
          edges {
            node {
              id
              frontmatter {
                category_name
                slug
              }
            }
          }
        }
      }
    `
  )
  return allMdx.edges
}
