import React, { useState } from "react"
import SocialMedia from "../SocialMedia"
import {
  NewsletterContainer,
  Title,
  ColoredBar,
  SubmitButton,
  Description,
  StyledInput,
  Form
} from "./style"

import { validateEmail } from "../../util/validateEmail"

const NewsLetterForm = () => {
  const [status, setStatus] = useState(null)
  const FORM_ID = "1801748"
  const FORM_URL = `https://app.convertkit.com/forms/${FORM_ID}/subscriptions`

  const handleSubmit = async e => {
    e.preventDefault()
    const inputValue = e.target.getElementsByTagName("input")[0].value

    if (validateEmail(inputValue)) {
      const data = new FormData(e.target)

      try {
        setStatus("LOADING")

        const response = await fetch(FORM_URL, {
          method: "post",
          body: data,
          headers: {
            accept: "application/json"
          }
        })

        const json = await response.json()

        if (json.status === "success") {
          setStatus("SUCCESS")
          return
        }

        setStatus("ERROR")
      } catch (err) {
        setStatus("ERROR")
      }
    } else {
      setStatus("INVALID")
    }
  }

  return (
    <NewsletterContainer>
      <ColoredBar />
      <Title>Newsletter</Title>
      <Description>
        Subscribe to get emails about new articles. No spam ever.
        <br />
        Unsubscribe any time.
      </Description>
      <Form action={FORM_URL} method="post" onSubmit={handleSubmit}>
        <StyledInput
          type="email"
          aria-label="Your email"
          name="email_address"
          placeholder="Your email address"
          required
        />
        <SubmitButton type="submit" disabled={status === "LOADING"}>
          {status === "LOADING" ? "Loading" : "Subscribe"}
        </SubmitButton>
        {status === "SUCCESS" && (
          <p>Check your inbox to confirm your subscription!</p>
        )}
        {status === "ERROR" && (
          <p>Uh oh, something went wrong. Please try again.</p>
        )}
        {status === "INVALID" && <p>Invailid email. Please try again.</p>}
      </Form>
      <br />
      <SocialMedia align="center" />
    </NewsletterContainer>
  )
}

export default NewsLetterForm
