import styled from "styled-components"

export const StyledInput = styled.input`
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 3px;
  padding: 1rem 2rem;
  font-size: 16px;
  color: ${props => props.theme.colors.font.main};
  transition: 0.15s all ease;

  &:active,
  &:focus {
    border: 1px solid ${props => props.theme.colors.font.main};
    outline: none;
  }
`
