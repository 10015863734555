import React from "react"
import SearchBar from "../SearchBar"
import CategoryTag from "../CategoryTag"
import { Container } from "../Container"
import CategoryDropdown from "./CategoryDropdown"
import { useAllCategories } from "../../hooks/useAllCategories"
import { CategoriesContainer, CategoriesBar } from "./style"
import { useIsMd } from "../../hooks/utils"

const Categories = ({ location }) => {
  const categories = useAllCategories()
  const isMd = useIsMd()

  return (
    <Container>
      <CategoriesBar>
        <CategoriesContainer>
          {categories
            .sort((a, b) =>
              a.node.frontmatter.category_name.localeCompare(
                b.node.frontmatter.category_name
              )
            )
            .map(category => (
              <CategoryTag
                to={`/category/${category.node.frontmatter.slug}/`}
                key={category.node.id}
              >
                {category.node.frontmatter.category_name}
              </CategoryTag>
            ))}
        </CategoriesContainer>
        {isMd && <CategoryDropdown options={categories} />}
        <SearchBar location={location} />
      </CategoriesBar>
    </Container>
  )
}

export default Categories
