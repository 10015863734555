import React, { useState, useRef } from "react"
import { Link } from "gatsby"
import UWIcon from "../../images/icons/uw.svg"
import { Menu, LogoIcon, NavContainer, MenuContainer } from "./style"
import { useIsSm } from "../../hooks/utils"
import { useOnClickOutside } from "../../hooks/useOnClickOutside"
import Hamburger from "./Hamburger"

const Nav = () => {
  const [open, setOpen] = useState(false)
  const isSm = useIsSm()
  const node = useRef()
  const menuId = "main-menu"

  useOnClickOutside(node, () => setOpen(false))

  return (
    <NavContainer>
      <LogoIcon>
        <Link to="/">
          <UWIcon />
        </Link>
      </LogoIcon>
      <MenuContainer ref={node}>
        {isSm && (
          <Hamburger open={open} setOpen={setOpen} aria-controls={menuId} />
        )}
        <Menu id={menuId} open={open} setOpen={setOpen} isMd={isSm}>
          <Link to="/" activeClassName="active">
            Home
          </Link>
          <Link to="/about/" activeClassName="active">
            About
          </Link>
          <Link to="/contact/" activeClassName="active">
            Contact
          </Link>
        </Menu>
      </MenuContainer>
    </NavContainer>
  )
}

export default Nav
