import styled from "styled-components"

export const Container = styled.div`
  margin-top: 1.5rem;
  text-align: ${props => props.align};
`

export const Link = styled.a`
  &:hover {
    svg > path {
      fill: ${props => props.theme.colors.hover};
    }
  }

  & + & {
    margin-left: 3.5rem;
  }
`
