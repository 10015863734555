import React from "react"
import { StyledInput } from "./style"

const Input = ({ placeholder, className, ...otherProps }) => {
  return (
    <StyledInput
      placeholder={placeholder}
      className={className}
      {...otherProps}
    />
  )
}

export default Input
