import styled from "styled-components"

export const StyledBurger = styled.button`
  position: absolute;
  top: 50%;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  outline: none;
  transform: translateY(-50%);
  z-index: 3;

  span {
    width: 2.2rem;
    height: 0.2rem;
    background: ${({ theme, open }) =>
      open ? theme.colors.font.main : theme.colors.font.main};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) =>
        open ? "rotate(45deg) translateY(-50%)" : "rotate(0) translateY(-50%)"};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) =>
        open
          ? "translateX(20px) translateY(-50%)"
          : "translateX(0) translateY(-50%)"};
    }

    :nth-child(3) {
      transform: ${({ open }) =>
        open
          ? "rotate(-45deg) translateY(-50%)"
          : "rotate(0) translateY(-50%)"};
    }
  }
`
