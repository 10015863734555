export const validateEmail = email => {
  if (email) {
    var trimmed = email.trim().toLowerCase()
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        trimmed
      )
    ) {
      return trimmed
    } else {
      return null
    }
  } else {
    return null
  }
}
