import styled from "styled-components"
import media from '../../theme/breakpoints'
import { Container } from "../Container"
import SecondaryHeaderImage from "../../images/header-secondary.svg"
import TertiaryHeaderImage from "../../images/header-tertiary.svg"
import Main from "../../images/main.svg"

export const NavContainer = styled.header`
  background: ${props => props.theme.colors.secondary};
  font-size: 0;
`

export const StyledHeader = styled(Container)`
  display: flex;
  align-items: center;
  padding-top: ${props => (props.type === "main" ? 4 : 3)}rem;
  padding-bottom: ${props => (props.type === "main" ? 4 : 3)}rem;
  
  ${media.md`
    flex-direction: column-reverse;
  `}
  
  h1 {
    white-space: nowrap;
    line-height: 1;
    margin: 0;
    text-align: left;

    ${media.md`
      text-align: center;
    `}
  }
`

export const TertiaryImage = styled(TertiaryHeaderImage)`
  max-height: 18rem;
  margin-left: auto;
  padding-left: 5rem;

  ${media.md`
    padding-left: 0;
    margin-right: auto;
    max-width: 20rem;
    height: auto;
  `}
`
  
export const SecondaryImage = styled(SecondaryHeaderImage)`
  max-height: 18rem;
  margin-left: auto;
  padding-left: 2rem;

  ${media.md`
    padding-left: 0;
    margin-right: auto;
    max-width: 20rem;
    height: auto;
  `}
`

export const MainImage = styled(Main)`
  width: 100%;
  height: auto;

  ${media.md`
    max-width: 50rem;
    margin-bottom: 2rem;
  `}
`

export const Tagline = styled.p`
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  color: ${props => props.theme.colors.font.secondary};
  text-align: left;
  margin-top: 0;

  ${media.md`
    text-align: center;
    font-size: 2.2rem;
  `}
`
