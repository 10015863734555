import React from "react"
import { ContainerMain } from "./style"

export const Container = ({ children, className, size }) => {
  return (
    <ContainerMain className={className} size={size}>
      {children}
    </ContainerMain>
  )
}
