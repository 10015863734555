import styled from "styled-components"
import media from "../../theme/breakpoints"

export const ContainerMain = styled.div`
  margin: 0 auto;
  max-width: ${props => (props.size === "small" ? 820 : 1030)}px;
  padding: 0 4rem;

  ${media.sm`
    padding: 0 2rem;
  `}
`
