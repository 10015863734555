import React from "react"
import { Link } from "gatsby"
import { MDXProvider } from "@mdx-js/react"

import { ThemeProvider } from "styled-components"
import { theme } from "../../theme"
import { GlobalStyle } from "../../theme/globalStyle"

import { Container } from "../Container"
import SEO from "../SEO"
import Nav from "../Nav"
import Header from "../Header"
import Categories from "../Categories"
import NewsletterForm from "../NewsletterForm"
import Footer from "../Footer"
import { AppContextProvider } from "../../context/index"
import { useSiteMetadata } from "../../hooks/useSiteMetadata"

import { Main, HeaderContainer } from "./style"

const CustomLink = ({ children, href }) => {
  if (href.startsWith("/")) {
    // Use Gatsby's Link component for internal site navigation
    // to benefit from the preloading features
    // See: https://www.gatsbyjs.org/docs/gatsby-link/
    return <Link to={href}>{children}</Link>
  }
  // Check if the link is for a section on the page
  // We don't want to add the attributes for the on page links
  const onPage = href.startsWith("#")
  return (
    <a
      href={href}
      // Open the link in a new page
      target={onPage ? null : "_blank"}
      // Add noopener and noreferrer for security reasons
      rel={onPage ? null : "noopener noreferrer"}
    >
      {children}
    </a>
  )
}

const Layout = ({
  children,
  location,
  title,
  type,
  description,
  seoTitle,
  tagline,
  image
}) => {
  const { title: siteTitle } = useSiteMetadata()

  const headerType = type => {
    switch (type) {
      case "main":
        return (
          <HeaderContainer>
            <Header title={title || siteTitle} type="main" tagline={tagline} />
            <Categories location={location} />
          </HeaderContainer>
        )
      default:
        return (
          <HeaderContainer>
            <Header type={type} title={title || siteTitle} />
          </HeaderContainer>
        )
    }
  }

  return (
    <AppContextProvider>
      <MDXProvider
        components={{
          // Map the HTML elements to React components
          a: CustomLink
        }}
      >
        <ThemeProvider theme={theme}>
          <SEO title={seoTitle || title} description={description} image={image} />
          <GlobalStyle />
          <Nav />
          {type && headerType(type)}
          <Container size={type === "main" ? "large" : "small"}>
            <Main>
              {children}
              <NewsletterForm />
            </Main>
            <Footer />
          </Container>
        </ThemeProvider>
      </MDXProvider>
    </AppContextProvider>
  )
}

export default Layout
