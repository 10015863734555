import styled from "styled-components"
import media from "../../theme/breakpoints"

export const Form = styled.form`
  display: flex;
  border: 1px solid
    ${props =>
      props.focus ? props.theme.colors.font.main : props.theme.colors.border};
  border-radius: 5px;
  height: 3.5rem;
  margin-left: 2rem;
  flex-basis: 100px;
  transition: 0.15s all ease;

  ${media.lg`
    margin-left: 0;
    margin-top: 2rem;
    width: 30rem;
  `}

  ${media.md`
    width: 100%;
    margin-top: 1rem;
  `}

  svg {
    margin: auto 1rem;
    height: 15px;
    width: 15px;
    fill: ${props => props.theme.colors.font.main};
  }

  input {
    display: flex;
    flex: 100%;
    height: 100%;
    font-size: 16px;
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    padding-right: 0.5rem;
    word-wrap: break-word;
    outline: none;
    font-family: ${props => props.theme.fontFamily.main};
  }
`

export const StyledSearchInput = styled.input`
  &:focus::-webkit-search-cancel-button {
    opacity: 0.8;
    pointer-events: all;
  }

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 1em;
    width: 1em;
    border-radius: 50em;
    background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg)
      no-repeat 50% 50%;
    opacity: 0;
    background-size: contain;
    pointer-events: none;

    &:hover {
      cursor: pointer;
    }
  }
`

export const Label = styled.label`
  font-size: 0;
  display: flex;
  align-items: center;
`
