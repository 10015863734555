import styled from "styled-components"

export const StyledButton = styled.button`
  background: ${props => props.theme.colors.main};
  border-radius: 3px;
  padding: 1rem 1.6rem;
  color: #fff;
  border: none;
  transition: 0.3s all ease;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid ${props => props.theme.colors.main};

  &:hover {
    background: ${props => props.theme.colors.hover};
    border: 1px solid ${props => props.theme.colors.hover};
    cursor: pointer;
  }

  &:disabled {
    background: ${props => props.theme.colors.border};
    border: 1px solid ${props => props.theme.colors.border};

    &:hover {
      cursor: not-allowed;
    }
  }
`
