import React, { useState } from "react"
import { navigate } from "gatsby"
import * as queryString from "query-string"

import SearchIcon from "../../images/icons/search.svg"

import { Form, StyledSearchInput, Label } from "./style"

const SearchBar = ({ location }) => {
  const { search } = queryString.parse(location.search)
  const [query, setQuery] = useState(search || "")
  const [focus, setFocus] = useState(false)

  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        navigate(`/search/?s=${query}`)
      }}
      focus={focus}
    >
      <Label htmlFor="search">
        <SearchIcon />
      </Label>
      <StyledSearchInput
        id="search"
        type="search"
        placeholder="Search"
        value={query}
        onChange={e => {
          setQuery(e.target.value)
        }}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
    </Form>
  )
}

export default SearchBar
