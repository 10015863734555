import React from "react"
import SocialMedia from "../SocialMedia"
import { useIsMd } from "../../hooks/utils"
import {
  NavContainer,
  StyledHeader,
  SecondaryImage,
  TertiaryImage,
  MainImage,
  Tagline
} from "./style"

const HeaderImages = {
  main: <MainImage />,
  secondary: <SecondaryImage />,
  tertiary: <TertiaryImage />
}

const Header = ({ type, title, tagline }) => {
  const isMd = useIsMd()
  return (
    <NavContainer>
      {type && (
        <StyledHeader type={type}>
          <div>
            <h1>{title}</h1>
            {type === "main" && (
              <>
                <Tagline>{tagline}</Tagline>
                <SocialMedia align={isMd ? "center" : "left"} />
              </>
            )}
          </div>
          {HeaderImages[type]}
        </StyledHeader>
      )}
    </NavContainer>
  )
}

export default Header
