import React from "react"

import { Tag } from "./style"

const CategoryTag = ({
  children,
  size = "large",
  className,
  ...otherProps
}) => {
  return (
    <Tag size={size} className={className} {...otherProps}>
      {children}
    </Tag>
  )
}

export default CategoryTag
