import styled from "styled-components"
import media from "../../theme/breakpoints"
import { Container } from "../Container"

export const NavContainer = styled(Container)`
  height: 7rem;
  display: flex;
  align-items: center;

  ${media.md`
    height: 5rem;
    position: sticky;
    top: 0;
    background: rgba(255, 255, 255, 0.95);
    z-index: 2;
  `}
`

export const LogoIcon = styled.div`
  margin-right: 5rem;
`

export const Menu = styled.nav`
  ${media.sm`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgba(255, 255, 255, 0.95);
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    height: calc(100vh - 5rem);
    text-align: left;
    padding: 2rem 2rem 5rem;
    position: fixed;
    top: 5rem;
    right: 0;
    transition: transform 0.3s ease-in-out;
    width: 20rem;
    z-index: 2;
  `}

  a {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.6rem;
    color: ${props => props.theme.colors.font.main};
    text-decoration: none;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: ${props => props.theme.colors.font.main};
      opacity: 0;
      transition: all 0.3s ease 0s;
    }

    + a {
      margin-left: 5rem;

      ${media.sm`
        margin-left: 0;
        margin-top: 1.8rem;
      `}
    }

    &:hover {
      &::before {
        opacity: 1;
        width: 100%;
      }
    }

    &.active {
      &::before {
        opacity: 1;
        width: 100%;
        transition: all 0.3s ease 0s;
      }
    }
  }
`
export const MenuContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
`
