import styled from "styled-components"
import media from '../../theme/breakpoints'

export const CategoriesBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3.5rem;
  margin-bottom: 2rem;

  ${media.lg`
    display: block;
  `}
`

export const CategoriesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-basis: 58rem;

  ${media.lg`
    max-width: 58rem;
  `}

  ${media.md`
    display: none;
  `}
`
