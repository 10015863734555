export const theme = {
  colors: {
    main: "#3875fc",
    secondary: "#ebf7ff",
    hover: "#0a54d4",
    font: {
      main: "#35323e",
      secondary: "#717171"
    },
    border: "#dcdcdc",
    borderLight: "rgb(234,234,234)"
  },
  fontFamily: {
    mainHeader: "'Arya', sans-serif",
    main: "'Nunito Sans', sans-serif",
    text: "'Source Serif Pro', serif"
  }
}
