import { useContext } from "react"
import { AppContext } from "../context"
import { breakpoints } from "../theme/breakpoints"

export const useWidth = () => {
  const { width } = useContext(AppContext)
  return width
}

export const useIsLg = () => {
  const width = useWidth()
  return width <= breakpoints.lg
}

export const useIsMd = () => {
  const width = useWidth()
  return width <= breakpoints.md
}

export const useIsSm = () => {
  const width = useWidth()
  return width <= breakpoints.sm
}
