import React from "react"
import { navigate } from "gatsby"
import { StyledDropdown } from "./style"
import "react-dropdown/style.css"

const CategoryDropdown = ({ options }) => {
  const formattedOptions = options.map(category => ({
    value: `/category/${category.node.frontmatter.slug}/`,
    label: category.node.frontmatter.category_name
  }))

  const handleChange = e => {
    navigate(e.value)
  }

  return (
    <StyledDropdown
      options={formattedOptions}
      onChange={handleChange}
      placeholder="Select Category"
    />
  )
}

export default CategoryDropdown
