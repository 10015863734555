import { graphql, useStaticQuery } from "gatsby"

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          siteMetadata {
            image
            siteUrl
            title
            author
            description
            social {
              twitter
              instagram
              linkedin
            }
          }
        }
      }
    `
  )
  return site.siteMetadata
}
