import styled from "styled-components"
import media from "../../theme/breakpoints"

export const Main = styled.main`
  padding-bottom: 10rem;
`

export const HeaderContainer = styled.div`
  margin-bottom: 5rem;

  ${media.lg`
    margin-bottom: 3rem;
  `}
`
