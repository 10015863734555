import styled from "styled-components"

export const Container = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5rem;
  width: 100%;
  color: ${props => props.theme.colors.font.secondary};
`
