import React from "react"
import TwitterIcon from "../../images/icons/twitter.svg"
import InstagramIcon from "../../images/icons/instagram.svg"
import LinkedinIcon from "../../images/icons/linkedin.svg"

import { Container, Link } from "./style"

import { useSiteMetadata } from "../../hooks/useSiteMetadata"

const SocialMedia = ({ align = "left", className }) => {
  const { social } = useSiteMetadata()

  return (
    <Container align={align} className={className}>
      <Link
        href={`https://twitter.com/${social.twitter}`}
        target="_blank"
        rel="noreferrer"
      >
        <TwitterIcon />
      </Link>
      <Link
        href={`https://instagram.com/${social.instagram}`}
        target="_blank"
        rel="noreferrer"
      >
        <InstagramIcon />
      </Link>
      <Link
        href={`https://linkedin.com/in/${social.linkedin}`}
        target="_blank"
        rel="noreferrer"
      >
        <LinkedinIcon />
      </Link>
    </Container>
  )
}

export default SocialMedia
