import { createGlobalStyle } from "styled-components"
import normalize from "./normalize.css"
import media from "./breakpoints"

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  html {
    font-size: 62.5%;
    margin: 0;
    padding: 0;
  }

  body {
    font-size: 1.6rem;
    margin: 0;
		padding: 0;
    font-family: ${props => props.theme.fontFamily.main};
    color: ${props => props.theme.colors.font.main};
    -webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-weight: 400;
    min-height: 100vh;
    position: relative;
  }

  h1 {
    font-family: ${props => props.theme.fontFamily.mainHeader};
    font-size: 8.4rem;
    font-weight: 700;
    margin: 0;

    ${media.lg`
      font-size: 7.2rem;
    `}

    ${media.md`
      font-size: 6.4rem;
    `}
  }

  h2 {
    font-family: ${props => props.theme.fontFamily.main};
    font-weight: 700;
    font-size: 4.2rem;
    line-height: 6.5rem;
    margin: 0;
  }
  
  h3 {
    font-weight: 700;
    font-size: 3.6rem;
    line-height: 5rem;
    margin: 0;

    ${media.md`
      font-size: 3rem;
      line-height: 1;
    `}
  }

  h4 {
    font-weight: 900;
    font-size: 2.4rem;
    line-height: 120%;
    margin: 0;
  }
`
